<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Classy Integration for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Connect this event with an Classy campaign for automatic fundraiser sync.
        </p>

        <v-alert v-if="isConnectedWithClassy" type="success">
          <p>This event is connected with Classy. Registration data is synced <strong>every 15 minutes</strong> automatically.</p>
          <p><v-btn @click="syncNow">Full Sync Now</v-btn></p>
        </v-alert>
        <v-alert v-if="event.sync" type="success">
          <p>Last sync was at {{ event.sync.timestamp | localDate('ddd L LTS Z') }}: <strong>{{ event.sync.msg }}</strong></p>
        </v-alert>

        <div v-if="!isConnectedWithClassy">
          <v-alert type="info">
            <p>This event is not yet connected with Classy. Please reach out to us to request information on setting up this integration.</p>
          </v-alert>
        </div>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "IntegrationsEventsCom",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      provider: null,
      selectedProductId: null,
      siteData: siteData,
      eventsComEvents: [],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },


    async syncNow() {
      const response = (await eventManagerService.integrationsClassySync(this.event.id)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully synced registration data.');
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Integrations', exact: true, to: { name: 'eventmanagerIntegrations', params: {id: this.event.id}} },
        { text: `Classy Integration`, disabled: true },
      ];
    },
    isConnectedWithClassy() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'classy.id');
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

